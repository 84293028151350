<template>
  <CentralizeLayout>
    <div class="bg-1"></div>
    <div class="bg-2"></div>
    <b-col lg="4">
      <div class="text-center mb-4">
        <logo name="logo" />
      </div>
      <b-alert class="mt-3" :show="hasInvitation" :variant="'success'">{{
        $t("hasBeenInvited", { teamName })
      }}</b-alert>
      <b-alert
        class="mt-3"
        :show="hasAuthenticationStatus"
        :variant="'danger'"
        >{{ $t(`errorLogin.${errorCode}`) }}</b-alert
      >
      <b-card class="p-2 auth-card">
        <h5 class="mb-4">{{ $t("loginYourAccount") }}</h5>
        <b-form class="mt-3" novalidate @submit.prevent="onSubmit">
          <b-form-group :label="$t('email')">
            <b-form-input
              v-model.trim.lazy="$v.form.email.$model"
              type="email"
              required
              :state="validateState('email')"
              :disabled="isLoading"
              @blur="$v.form.email.$touch()"
            />
            <b-form-invalid-feedback>{{
              $t("emailErrMsg")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('password')" class="mt-3 position-relative">
            <b-form-input
              v-model.trim.lazy="$v.form.password.$model"
              :type="showPassword ? 'text' : 'password'"
              required
              maxlength="12"
              :state="validateState('password')"
              :disabled="isLoading"
              @blur="$v.form.password.$touch()"
            />
            <b-form-invalid-feedback>{{
              $t("passwordErrMsg")
            }}</b-form-invalid-feedback>
            <b-icon
              :icon="showPassword ? 'eye' : 'eye-slash'"
              @click="showPassword = !showPassword"
              class="input-icon"
              v-if="!$v.form.password.$invalid"
            ></b-icon>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox v-model="rememberDevice">
              {{ $t("keepMeLoggedIn") }}
            </b-form-checkbox>
          </b-form-group>
          <b-button
            class="mt-3"
            block
            :disabled="isLoading"
            type="submit"
            variant="primary"
          >
            <b-icon-arrow-clockwise v-show="isLoading" animation="spin" />
            {{ $t("signIn") }}
          </b-button>
        </b-form>
        <div
          class="mt-4 d-flex justify-content-center flex-row align-items-center"
        >
          <span>{{ $t("dontHaveAccount") }}</span>
          <b-button
            class="p-0 ml-1"
            variant="link"
            :to="{ name: 'Register', query: { lang: this.$route.query.lang } }"
            >{{ $t("createAccount") }}</b-button
          >
        </div>
      </b-card>
      <div class="mt-2 d-flex justify-content-center">
        <b-button
          variant="link"
          :to="{
            name: 'ResetPassword',
            query: { lang: this.$route.query.lang }
          }"
          >{{ $t("forgotPassword") }}</b-button
        >
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          variant="link"
          :to="{
            name: 'ConfirmSignUp',
            query: { lang: this.$route.query.lang }
          }"
          >{{ $t("activateMyPassword") }}</b-button
        >
      </div>
    </b-col>
    <b-col lg="4" class="text-footer">
      <div>
        <span class="mr-2">{{ $t("contact") }}</span>
        <span class="mr-2">{{ $t("legal") }}</span>
        <span>{{ $t("privacy") }}</span>
      </div>

      <div class="ml-auto">
        <b-dropdown class="language-dropdown" dropup no-caret right>
          <template #button-content>
            <div class="d-flex flex-row align-items-center">
              <icon class="mr-2" name="language" />
              <span v-if="$root.$i18n.locale === 'en'" style="font-size: 12px;"
                >English</span
              >
              <span v-if="$root.$i18n.locale === 'id'" style="font-size: 12px;"
                >Indonesia</span
              >
              <b-icon
                class="caret ml-1"
                icon="caret-up-fill"
                font-scale="0.85"
              />
            </div>
          </template>
          <b-dropdown-item @click="setLanguageEn">English</b-dropdown-item>
          <b-dropdown-item @click="setLanguageId"
            >Bahasa Indonesia</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </b-col>
  </CentralizeLayout>
</template>

<i18n>
{
  "en": {
    "email": "Email",
    "emailErrMsg": "Enter your email address.",
    "password": "Password",
    "passwordErrMsg": "Enter your password.",
    "forgotPassword": "Forgot password?",
    "signIn": "Sign in",
    "createAccount": "Create account",
    "errorLogin": {
      "NotAuthorizedException": "Incorrect username or password.",
      "unspecific": "Incorrect username or password."
    },
    "loginYourAccount" : "Login your account",
    "keepMeLoggedIn" : "Keep me logged in",
    "activateMyPassword": "I need to activate my password",
    "dontHaveAccount": "Dont have an account?",
    "accountActivated": "Account has created and activated successfully",
    "contact": "Contact",
    "legal": "Legal",
    "privacy": "Privacy & terms",
    "languageSaved": "Language is saved",
    "hasBeenInvited": "You have been invited to collaborate with {teamName}. Please login or register to accept invitation."
  },
  "id": {
    "email": "Email",
    "emailErrMsg": "Masukkan alamat email anda.",
    "password": "Kata sandi",
    "passwordErrMsg": "Masukkan kata sandi anda.",
    "forgotPassword": "Lupa kata sandi?",
    "signIn": "Masuk",
    "createAccount": "Buat akun",
    "errorLogin": {
      "NotAuthorizedException": "Alamat email atau kata sandi salah.",
      "unspecific": "Alamat email atau kata sandi salah."
    },
    "loginYourAccount" : "Masuk ke akun anda",
    "keepMeLoggedIn" : "Biarkan saya tetap masuk",
    "activateMyPassword": "Saya perlu mengaktifkan password",
    "dontHaveAccount": "Tidak punya akun?",
    "accountActivated": "Akun berhasil dibuat dan diaktifkan",
    "contact": "Kontak",
    "legal": "Hukum",
    "privacy": "Privasi & persyaratan",
    "languageSaved": "Bahasa disimpan",
    "hasBeenInvited": "Anda telah diundang untuk berkolaborasi dengan {teamName}. Silahkan masuk atau register untuk menerima undangan."
  }
}
</i18n>

<script>
import { required, email } from "vuelidate/lib/validators";
import CentralizeLayout from "@/layouts/CentralizeLayout";
import { mapGetters, mapActions } from "vuex";

const errorCodes = ["NotAuthorizedException"];
const fallbackErrorCode = "unspecific";

export default {
  components: {
    CentralizeLayout
  },
  props: {
    email: {
      type: String
    },
    lang: {
      type: String,
      default: "en"
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        password: ""
      },
      rememberDevice: false,
      showPassword: false
    };
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationStatus",
      "authenticationStatus",
      "isAccountActivated",
      "isAuthenticated"
    ]),
    ...mapGetters("profile", ["hasProfiled"]),
    errorCode() {
      return errorCodes.includes(this.authenticationStatus.code)
        ? this.authenticationStatus.code
        : fallbackErrorCode;
    },
    hasInvitation() {
      return !!this.$route.query.invite;
    },
    teamName() {
      return this.$route.query.invite
        ? this.$route.query.invite.split("#")[0]
        : "team";
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  mounted() {
    this.clearAuthenticationStatus();
    if (this.email) {
      this.form.email = this.email;
    }

    if (this.isAccountActivated) {
      this.$toast.success(this.$t("accountActivated"));
    }

    if (this.lang === "en") {
      this.$root.$i18n.locale = "en";
    } else {
      this.$root.$i18n.locale = "id";
    }
  },
  methods: {
    ...mapActions("auth", ["clearAuthenticationStatus", "signIn"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty && $error ? false : null;
    },
    setLanguageEn() {
      this.$router.replace({ query: { lang: "en" } });
    },
    setLanguageId() {
      this.$router.replace({ query: { lang: "id" } });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.isLoading = true;
      await this.signIn({
        username: this.form.email,
        password: this.form.password,
        remember: this.rememberDevice
      });
      if (!this.hasAuthenticationStatus) {
        // if user load the site on route without auth guard, navigate to requiresAuth route
        if (this.isAuthenticated) {
          this.$router.push({
            name: "Workspace"
          });
        }
      }
      this.isLoading = false;
    }
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        if (this.$route.query.lang === "id") {
          this.$root.$i18n.locale = "id";
        } else {
          this.$root.$i18n.locale = "en";
        }
      });
    }
  }
};
</script>
